export const GET_RESTAURANT_SUCCESS = "GET_RESTAURANT_SUCCESS";
export const GET_RESTAURANT_FAILURE = "GET_RESTAURANT_FAILURE";

export const GET_RESTAURANTS_SUCCESS = "GET_RESTAURANTS_SUCCESS";
export const GET_RESTAURANTS_FAILURE = "GET_RESTAURANTS_FAILURE";

export const RESET_ADD_RESTAURANT = "RESET_ADD_RESTAURANT";
export const ADD_RESTAURANT_SUCCESS = "ADD_RESTAURANT_SUCCESS";
export const ADD_RESTAURANT_FAILURE = "ADD_RESTAURANT_FAILURE";

export const RESET_EDIT_RESTAURANT = "RESET_EDIT_RESTAURANT";
export const EDIT_RESTAURANT_SUCCESS = "EDIT_RESTAURANT_SUCCESS";
export const EDIT_RESTAURANT_FAILURE = "EDIT_RESTAURANT_FAILURE";

export const RESET_DELETE_RESTAURANT = "RESET_DELETE_RESTAURANT";
export const DELETE_RESTAURANT_SUCCESS = "DELETE_RESTAURANT_SUCCESS";
export const DELETE_RESTAURANT_FAILURE = "DELETE_RESTAURANT_FAILURE";