export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";

export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

export const RESET_ADD_ORDER = "RESET_ADD_ORDER";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAILURE = "ADD_ORDER_FAILURE";

export const RESET_EDIT_ORDER = "RESET_EDIT_ORDER";
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_FAILURE = "EDIT_ORDER_FAILURE";

export const BILL_ORDER_SUCCESS = "BILL_ORDER_SUCCESS";
export const BILL_ORDER_FAILURE = "BILL_ORDER_FAILURE";